
import { useState,useEffect,useRef } from 'react';
import './App.css';
import {getFirestore,where,getDocs, addDoc,updateDoc, collection, onSnapshot, doc,setDoc,query,deleteDoc, orderBy} from "firebase/firestore"
import {getStorage, listAll,ref,getDownloadURL} from 'firebase/storage'
import PlanStage from './PlanStage';
import HeaderBar from './HeaderStuff/HeaderBar';
import OptionsBar from './HeaderStuff/OptionsBar';
import ZoomButtons from './HeaderStuff/ZoomButtons';
import PinPopup from '../SVGBin/PinPopup'
import PinListUI from '../SVGBin/PinListUI'
import PageSidebar from './PagesBookmark/PagesSidebar';
import BookmarkPopup from './PagesBookmark/BookmarkPopup';
import NewProductPopup from './EstimationStation/NewProductPopup';
import {FBDataBase, FBStorage, FBApp} from '../Firebase/firebase'
import {
  BsFillPencilFill,
  BsHourglassSplit,
  BsFillDoorOpenFill,
  BsSafe2Fill,
  BsBoxFill,
  BsBox2Fill,
  BsDropbox,
  BsFillDropletFill,
  BsDropletHalf,
  BsFillHouseFill,
  BsFillLampFill,
  BsModemFill,
  BsPcDisplay,
  BsMotherboardFill,
  BsProjectorFill,
  BsFillLightbulbFill,
  BsFillLightningChargeFill,
  BsFillLightningFill,
  BsBatteryCharging,
  BsFillMagnetFill,
  BsFillPlugFill,
  BsEthernet,
  BsEvStationFill,
  BsOutlet,
  BsFileLock2Fill,
  BsFan,
  BsGearWideConnected,
  BsGearWide,
  BsNutFill,
  BsHammer,
  BsTools,
  BsFillWrenchAdjustableCircleFill,
  BsConeStriped,
  BsTrash3Fill,
  BsFillTagFill,
  BsPostageFill,
  BsPostage,
  BsPostageHeartFill,
  BsFillTreeFill,
  BsTree,
  BsFillPinFill,
  BsCameraFill,
  BsCoin,
  BsRadioactive,
  BsSuitSpadeFill,
  BsSuitClubFill,
  BsSuitHeartFill,
  BsSuitDiamondFill,
  BsFire,
  BsGiftFill,
  BsPiggyBankFill,
} from 'react-icons/bs'
import {
  MdKey,
  MdVpnKey,
  MdEco,
  MdEnergySavingsLeaf,
  MdStars,
  MdStarRate,
  MdGesture,
  MdWaves,
  MdAir,
  MdCable,
  MdPriceCheck,
  MdWidgets,
  MdFormatPaint,
  MdBrush,
  MdGridOn,
  MdGrain,
  MdReceiptLong,
  MdDesignServices,
  MdLocalLaundryService,
  MdLocalHotel,
  MdLocalFlorist,
  MdLocalAtm,
  MdPlumbing,
  MdAcUnit,
  MdMicrowave,
  MdLight,
  MdHive,
  MdRecycling,

} from 'react-icons/md'

import { useLocation } from 'react-router-dom';
function App() {
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// STATES, REFS, AND VARIABLES
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// URL PARAMETERS //////
const location = useLocation(); //Gets the HTTPS route for react-router purposes.
const routedData = location.state?.myData; // Immediately passed to the chosenPlanSet. Soon I'm sure I'll have to change this, when more data is passed from parent route.
const isDemo = location.state.isDemo;

////// APP-WIDE STATES & REFS //////
// const [chosenLayer, setChosenLayer] = useState("markup") THIS ISN'T USED, REMOVE FROM PROJECT
const [projectConstants,setProjectConstants] = useState({ // Project constant defaults. These are used all over the project, and change per-user.
  co: "AMP", // User company
  prefix:"gs://plan-room.appspot.com", //end-point for Firebase project. This won't change.
  defaultScale: 16 // The project scale. Although this should be page-by-page.
})
const [pageConstants,setPageConstants] = useState({ //The constants for each page. 
  stdImgSize: {x:8400,y:6000}, //This changes whenever a new image is loaded on screen, down in PlanStage.
  pageScale: 16, //This changes with the scaling tool. Although this state isn't per-page. NEED TO FIX AND MAKE PER-PAGE.
})
const [framePos, setFramePos] = useState({ //framePos is what holds the math that does the positioning of the PlanStage.
  scale:0.15, // This scale along with currentScaleRef determine the current zoom-scale. I think ? Idk double check.
  x: window.innerWidth / 2 - (pageConstants.stdImgSize.x * 0.3) / 2, //The X Position of the screen, adjusted for scale. This is the X the user sees, not the true X.
  y: window.innerHeight / 2 - (pageConstants.stdImgSize.y * 0.3) / 2, // The Y Position of the screen, adjusted for scale. This is the Y the user sees, not the true Y.
})
const [chosenPlanSet,setChosenPlanSet] = useState(routedData) //The chosenPlanSet is determined by the routed data that comes from the previous page's selection.
// const [planSetList, setPlanSetList] = useState([]) This is useless here. Remove in testing environment.
const [listPageToggle,setListPageToggle] = useState(true) // Do I even use this any longer? Maybe remove in testing environment.
const currentScaleRef = useRef(0.3) //This is the current zoom-scale reference. Not page scale. This is used all over the place.
const [imageSet, setImageSet] = useState([]) //This array holds all data for the set of images that are the blueprints.
const [pageNum, setPageNum] = useState(1) //An integer page number. Used frequently.
const pageNumRef = useRef() // A reference for page number, for use with number input in HeaderBar.
const [pageCount,setPageCount] = useState(0) //An integer page count that gives a total page count. For use on HeaderBar
const [darkMode,setDarkMode] = useState(undefined) //The state that contains the darkMode boolean.
const [chosenTool, setChosenTool] = useState("noTool") //This state contains a string value that is switched over in many other components to set what tool the user has selected.
const [chosenToolOption, setChosenToolOption] = useState(null) //The sub-option of the chosen tool. i.e. the highlighter of the markup tool. Often switched over
const [shiftToggle,setShiftToggle] = useState(false) //A boolean state that checks whether the user is holding down the shift key.
const [escButtonHit,setEscButtonHit] = useState(false) // A boolean state that inverses when the user hits the ESC key.
const [freshSVG, setFreshSVG] = useState(1); //A state that holds an integer. This integer can be changed and when it is, it re-loads the SVG layer.
const [freshESVG, setFreshESVG] = useState(2); //A state that holds an integer. This integer can be changed and when it is, it re-loads the ESVG layer.

////// SVG LAYER STATES Y REFS //////
const viewBoxRef = useRef(null) //The reference to the SVG "viewbow" ref. This references the top-level SVG layer for SVGLayer.js
const eviewBoxRef = useRef(null) //The reference to the ESVG "viewbow" ref. This references the top-level ESVG layer for ESVGLayer.js


////// PIN TOOL STATES & REFS //////
const [pinList, setPinList] = useState([]) //An array state that holds an array of pin data objs that are called & listened to in Firestore.
const [editingPinIndex, setEditingPinIndex] = useState(null) //Holds the index of the pinList array for whatever pin is currently being edited.
const [pinListOpen,setPinListOpen] = useState(false) //A boolean that changes when the user opens/closes the pinList uI.
const [pinVis,setPinVis] = useState(true) // A simple boolean that toggles on/off whether or not the pins are shown.

////// PAGES TOOL STATES & REFS //////
const [favoritePages,setFavoritePages] = useState([ //A simple array state that holds obj data of favored pages. Ignore the defaults here, they change immediately.
  {pageNum: 7, name: "Door Schedule"},
  {pageNum: 1,name:"Cover Page"},
  {pageNum: 1,name:"Cover Page"},
])
const [pageAddingState, setPageAddingState] = useState(false) //A boolean that changes when the user opens/closes the page adding window.

////// MARKUP TOOL STATES & REFS //////
const [marksList,setMarksList] = useState([]) //A simple state array that holds obj data of marks that are called & listed to in Firestore.
const [drawingOptions,setDrawingOptions] = useState({ //The infamous drawingOptions! These simple parameters change when the user updates their palette choices.
  size: 4,
  color: "red"
})
const [markVis,setMarkVis] = useState(true) //A simple boolean that hides/shows the marks.
const [chosenPalette,setChosenPalette] = useState({ //This state, while seemingly similar to drawingOptions, is in fact just to show the options on the palette dialogue box.
  color: "red",
  size: "M",
  tool: chosenToolOption,
  component: <BsFillPencilFill />
}) // Seriously all this state is used for is display, it does not FUNCTIONALLY do anything.

////// MEASURE TOOL STATES & REFS //////
const [forceMeasureUpdate,setForceMeasureUpdate] = useState(false) //A boolean toggle that is used to cause a re-render of the measurement tool, which is known to bug out. Crucial state here.
const [measureVis, setMeasureVis] = useState(true) //A boolean toggle that hides/shows the measurements.
const [measureList,setMeasureList] = useState([]) //An array state that holds obj data of all measurements pulled in from Firestore. 


////// ESTIMATE STATES //////
const multiplyRef = useRef() //A reference that holds an integer, and reflects the multiplier value of whatever product is selected.
const [selectedStampIndexes,setSelectedStampIndexes] = useState([]) //An array state that holds integer index values. These values reflect which of the productStamps are "selected".
const [forceUpdate,setForceUpdate] = useState(false) //A boolean state that forces an update on the ESVG layer. Crucial state for preventing big bugs.
const [productVis,setProductVis] = useState(true) //Boolean that hides/shows all product stamps.
const [countState,setCountState] = useState(new Map()) //A map (aka OBJ for my simpleton mind) that holds the counts for EVERY type of product stamp. Which is all calculated below.
const [productStamps,setProductStamps] = useState([]) //An array state that holds obj data about all product stamps. Big one.
const [productAddingState,setProductAddingState] = useState(false) //Boolean state determins whether the user is currently adding a product or not. Used so left click performs different operations.
const [productEditState,setProductEditState] = useState({bool:false,prod:null,index:null}) //This state controls the Edit Product window. It needs all 3 data points to properly edit the correct product.
const [chosenProduct,setChosenProduct] = useState(1) //Holds an integer, null, or negative integer value. If it holds positive integer, that is the index of a product in the array. If it holds "null", it defaults to basic user clicking. It if holds -1, that is the Lasso tool.
const [productCatalog,setProductCatalog] = useState([]) //Holds an array of products from the catalog. The catalog is created by each unique product created.
const [chosenItemsList,setChosenItemsList] = useState([ //Stores the current list of selected products. The default here is simply to show obj structure.
  {
    type:"stamp",
    shape:"square",
    color:"red",
    title:"Entry Door",
    size: 4,
    cost: 700,
    materials: [],
    qty: 0,
    icon: <BsSafe2Fill />
  }
])
const [iconRegistry,setIconRegistry] = useState({ //The iconRegistry is used for Products. Displaying React-Native icons from a string is a pain in the ass, and requires this Mapping.
"BsFillDoorOpenFill": <BsFillDoorOpenFill />,
"BsSafe2Fill": <BsSafe2Fill />,
"BsBoxFill": <BsBoxFill />,
"BsBox2Fill": <BsBox2Fill />,
"BsDropbox": <BsDropbox />,
"BsFillDropletFill": <BsFillDropletFill />,
"BsDropletHalf": <BsDropletHalf />,
"BsFillHouseFill": <BsFillHouseFill />,
"BsFillLampFill": <BsFillLampFill />,
"BsModemFill": <BsModemFill />,
"BsPcDisplay": <BsPcDisplay />,
"BsMotherboardFill": <BsMotherboardFill />,
"BsProjectorFill": <BsProjectorFill />,
"BsFillLightbulbFill": <BsFillLightbulbFill />,
"BsFillLightningChargeFill": <BsFillLightningChargeFill />,
"BsFillLightningFill": <BsFillLightningFill />,
"BsBatteryCharging": <BsBatteryCharging />,
"BsFillMagnetFill": <BsFillMagnetFill />,
"BsFillPlugFill": <BsFillPlugFill />,
"BsEthernet": <BsEthernet />,
"BsEvStationFill": <BsEvStationFill />,
"BsOutlet": <BsOutlet />,
"BsFileLock2Fill": <BsFileLock2Fill />,
"BsFan": <BsFan />,
"BsGearWideConnected": <BsGearWideConnected />,
"BsGearWide": <BsGearWide />,
"BsNutFill": <BsNutFill />,
"BsHammer": <BsHammer />,
"BsTools": <BsTools />,
"BsFillWrenchAdjustableCircleFill": <BsFillWrenchAdjustableCircleFill />,
"BsConeStriped": <BsConeStriped />,
"BsTrash3Fill": <BsTrash3Fill />,
"BsFillTagFill": <BsFillTagFill />,
"BsPostageFill": <BsPostageFill />,
"BsPostage": <BsPostage />,
"BsPostageHeartFill": <BsPostageHeartFill />,
"BsHourglassSplit": <BsHourglassSplit />,
"BsFillTreeFill": <BsFillTreeFill />,
"BsTree": <BsTree />,
"BsFillPinFill": <BsFillPinFill />,
"BsCameraFill": <BsCameraFill />,
"BsCoin": <BsCoin />,
"BsRadioactive": <BsRadioactive />,
"BsSuitSpadeFill": <BsSuitSpadeFill />,
"BsSuitClubFill": <BsSuitClubFill />,
"BsSuitHeartFill": <BsSuitHeartFill />,
"BsSuitDiamondFill": <BsSuitDiamondFill />,
"BsFire": <BsFire />,
"BsGiftFill": <BsGiftFill />,
"BsPiggyBankFill": <BsPiggyBankFill />,
"MdKey": <MdKey />,
"MdVpnKey": <MdVpnKey />,
"MdEco": <MdEco />,
"MdEnergySavingsLeaf": <MdEnergySavingsLeaf />,
"MdStars": <MdStars />,
"MdStarRate": <MdStarRate />,
"MdGesture": <MdGesture />,
"MdWaves": <MdWaves />,
"MdAir": <MdAir />,
"MdCable": <MdCable />,
"MdPriceCheck": <MdPriceCheck />,
"MdWidgets": <MdWidgets />,
"MdFormatPaint": <MdFormatPaint />,
"MdBrush": <MdBrush />,
"MdGridOn": <MdGridOn />,
"MdGrain": <MdGrain />,
"MdReceiptLong": <MdReceiptLong />,
"MdDesignServices": <MdDesignServices />,
"MdLocalLaundryService": <MdLocalLaundryService />,
"MdLocalHotel": <MdLocalHotel />,
"MdLocalFlorist": <MdLocalFlorist />,
"MdLocalAtm": <MdLocalAtm />,
"MdPlumbing": <MdPlumbing />,
"MdAcUnit": <MdAcUnit />,
"MdMicrowave": <MdMicrowave />,
"MdLight": <MdLight />,
"MdHive": <MdHive />,
"MdRecycling": <MdRecycling />,
})


////// SERVERLESS CLOUD FUNCTION STATES ///////



///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// FIREBASE CONSTANTS AND INITIALIZATION
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

//Good ol' Firebase Config. Stores all information needed to use the Firebase API.
// ** TO-DO MOVE APIKEY TO A LOCAL ENVIRONMENT VARIABLE SO IT DOESN'T GO TO GITHUB. ** !!!!!!!!

const [folderRef, setFolderRef] = useState(ref(FBStorage, `${projectConstants.prefix}/${projectConstants.co}/${chosenPlanSet}`)) //This state is likely utilized in the parent page. Probably no longer needed!!!!
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// REGULAR REACT USE EFFECTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

//This useEffect fetches all pages from the selected project, whenver the chosenPlanSet changes.
//Then it downloads all of the .png files from the given URL's from FireBase Storage.
//And with those .png links it sorts them by name and pushes them into an array "urlPassThruArray"
//That passThruArray is then used as a parameter to setImageSet, which gives us the ability to call pages from a state.
//We then run a for-loop over the urlPassThruArray, incrementing a simple integer count.
//That integer count is then assigned to pageCount state, setting the page count of all the .pngs we just called.
//Once that's all said and done, we check the state of the darkMode local variable, 
useEffect(()=>{
  let urlPassThruArray = [["pageZeroUrl",0]];
  let pageStorage = ref(FBStorage, `${projectConstants.prefix}/${projectConstants.co}/${chosenPlanSet}`)
  listAll(pageStorage).then((response) => {
      const urlPromises = response.items.map((item) => {
          return getDownloadURL(item).then(url => ({ url, item }));
      });
      // Only update the state and log once all URLs have been fetched.
      Promise.all(urlPromises).then((urlsWithItems) => {
          // Sort the URLs based on the page number in the filename.
          urlsWithItems.sort((a, b) => {
              const pageANumber = (a.item.name);
              const pageBNumber = (b.item.name);
              return pageANumber - pageBNumber;
          });
          // Push the sorted URLs into urlPassThruArray.
          urlsWithItems.forEach(({ url }, index) => {
              urlPassThruArray.push([url, index + 1]);
          });
          setImageSet(urlPassThruArray);
          console.log(urlPassThruArray);
          //Page count & plan pages setup
          let pageCountVar = 0
          console.log(urlPassThruArray.length)
          for (let i=1;i<urlPassThruArray.length;i++){
            pageCountVar++
          }
          setPageCount(pageCountVar)
      });
  });
  //Dark Mode Setup
  const localStoreDark = localStorage.getItem('darkMode') === "true"
  console.log(localStoreDark)
  if (localStoreDark == true) {setDarkMode(true)} else {setDarkMode(false)}
  setFolderRef(ref(FBStorage, `${projectConstants.prefix}/${projectConstants.co}/${chosenPlanSet}`)) //USELESS I BELIEVE // ARCHAIC
},[chosenPlanSet])


//This useEffect listens to keyDown events. And for some reason runs whenever selectedStampIndexes changes ?? Not sure why but DON'T CHANGE lol.
//First we have the handleKeyDown func, which takes the event prop. Using that event we can determine which key was pressed, and we switch over it with an if statement.
//ESC key pretty much re-renders a lot of stuff and sets the user back to square1.
//Delete deletes any selected stamps.
//ShiftLeft && ShiftRight are used to change the state of the shiftToggle state. This is used for line snapping for the line or polygon tools. Also makes the rectangle a perfect square.
//I'm also sure to establish event listeners, and again clear them when the component dismounts. Which is kinda stupid since we're in App but nonetheless.
useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setChosenToolOption("");
      setSelectedStampIndexes([]);
      setChosenProduct(null);
      setEditingPinIndex(null);
      // setEscButtonHit(prev => !prev)
      refreshESVG()
      refreshSVG()
      console.log('esc');
    } else if (event.key === "Delete") {
      console.log('delete key');
      deleteMultipleStampsFunc();
    } else if ((event.code === 'ShiftLeft' || event.code === 'ShiftRight') && event.type === 'keydown') {
      setShiftToggle(prev => !prev)
      // if (shiftToggle) {
      //   setShiftToggle(false)
      // } else {
      //   setShiftToggle(true)
      // }
      // setShiftToggle(true);
      console.log('shift down');
    } 
    // else if ((event.code === 'ShiftLeft' || event.code === 'ShiftRight') && event.type === 'keyup') {
    //   setShiftToggle(false);
    //   console.log('shift up');
    // }
  };
  window.addEventListener('keydown', handleKeyDown);
  window.addEventListener('keyup', handleKeyDown); // Add keyup listener
  // Clean up the event listeners when the component unmounts
  return () => {
    window.removeEventListener('keydown', handleKeyDown);
    window.removeEventListener('keyup', handleKeyDown); // Remove keyup listener
  };
}, [selectedStampIndexes]);


///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// FIREBASE SNAPSHOTS USE EFFECTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
// I'm not going to comment too much on these, they're simple Firebase collection listeners.


////// PINS //////
useEffect(() => {
    const q = query(collection(FBDataBase, projectConstants.co, chosenPlanSet, "pins"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let pins = [];
        querySnapshot.forEach((document) => {
            // console.log(document);
            let pin = document.data();
            pin.id = document.id;
            pins.push(pin);
        });
        console.log(pins);
        setPinList(pins)
    });
    return () => unsubscribe();
}, [FBDataBase,chosenPlanSet]);

////// MARKS //////
useEffect(() => {
  const q = query(collection(FBDataBase, projectConstants.co, chosenPlanSet, "marks"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let marks = [];
      querySnapshot.forEach((document) => {
          // console.log(document);
          let mark = document.data();
          mark.id = document.id;
          marks.push(mark);
      });
      console.log(marks);
      setMarksList(marks)
  });
  return () => unsubscribe();
}, [FBDataBase,chosenPlanSet]);

////// MEASURES //////
useEffect(() => {
  const q = query(collection(FBDataBase, projectConstants.co, chosenPlanSet, "measures"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let measures = [];
      querySnapshot.forEach((document) => {
          // console.log(document);
          let measure = document.data();
          measure.id = document.id;
          measures.push(measure);
      });
      console.log(measures);
      setMeasureList(measures)
  });
  return () => unsubscribe();
}, [FBDataBase,chosenPlanSet]);

////// PAGES //////
useEffect(() => {
  const q = query(collection(FBDataBase, projectConstants.co, chosenPlanSet, "pages"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let pages = [];
      querySnapshot.forEach((document) => {
          // console.log(document);
          let bookMark = document.data();
          bookMark.id = document.id;
          pages.push(bookMark);
      });
      console.log(pages);
      setFavoritePages(pages.sort((a,b)=>a.pageNum - b.pageNum))
  });
  return () => unsubscribe();
}, [FBDataBase,chosenPlanSet]);

////// MASTER DOCUMENT //////
// I BELIEVE THIS IS GOOD TO BE REMOVED NOW.
// BECAUSE THIS FUNC ONCE LISTENED FOR CHANGES IN INDEX, WHICH IS THE LIST OF ALL JOBS A USER HAS.
//THAT IS NOW HANDLED IN THE PREVIOUS PAGE. REMOVE IN A TESTING ENVIRONMENT. 

// useEffect(() => {
//   const docRef = doc(FBDataBase, projectConstants.co, "INDEX");

//   const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
//     if (docSnapshot.exists()) {
//       let docData = docSnapshot.data()
//       console.log("Document data:", docData.planSets);
//       setPlanSetList([...docData.planSets])
//       console.log([...docData.planSets])
//       console.log(projectConstants)
//     } else {
//       console.log("No such document!");
//     }
//   });

//   return () => unsubscribe();
// }, [FBDataBase,chosenPlanSet]);

////// PRODUCTS //////
useEffect(() => {
  const q = query(collection(FBDataBase, projectConstants.co, chosenPlanSet, "products"), orderBy("title"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let products = [];
      querySnapshot.forEach((document) => {
          // console.log(document);
          let product = document.data();
          product.id = document.id;
          products.push(product);
      });
      console.log(products);
      setChosenItemsList(products)
  });
  return () => unsubscribe();
}, [FBDataBase,chosenPlanSet]);

////// PRODUCT STAMPS //////
//Since this one is more complex & highly unique I will in fact comment.
//Everything is standard until we get into the querySnapshot.forEach()
//We first get the multiplier in the multiplier variable, or default it to one.
//We secondly break out the different types of products, and do different calculations based off of their type.
//These calculations take the multiplier and the singular measurement of the product. i.e. 10ft of baseboard * 2 multiplier == 20ft baseboard.
//These calculatinos are then saved to countsState.
useEffect(() => {
  const q = query(collection(FBDataBase, projectConstants.co, chosenPlanSet, "stamps"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let stamps = [];
      let counts = new Map();
      querySnapshot.forEach((document) => {
          let stamp = document.data();
          stamp.id = document.id;
          stamps.push(stamp);
          let multiplier = stamp.multiplier || 1;
          let count = counts.get(stamp.matchingId) || 0;
          if (stamp.type == "stamp") {
            counts.set(stamp.matchingId, count + (1 * multiplier));
          } else if (stamp.type=="line") {
            console.log(stamp.distanceFT.ft)
            counts.set(stamp.matchingId, count + parseInt(1 * multiplier * stamp.distanceFT.ft))
          } else if (stamp.type=="areaBox"){
            counts.set(stamp.matchingId, count + parseInt(1 * multiplier * stamp.areaFT.ft))
          } else if (stamp.type=="polygon"){
            counts.set(stamp.matchingId, count + parseInt(1 * multiplier * stamp.areaFT.ft))
          }
      });
      console.log(Array.from(counts.entries())); //This console log shows me an array of all of the product counts & their multiplied values.
      console.log(counts);
      setCountState(counts);
      setProductStamps(stamps);
  });

  return () => unsubscribe();
}, [FBDataBase, chosenPlanSet]);

////// PRODUCT CATALOG //////
//I'll go over this one too, since it's pretty unique.
//First we listen to the catalog collection.
//When we map over it, we push them into a blank stamps array.
//That array is then sorted over, and ordered in alphabetical order.
useEffect(() => {
  const q = query(collection(FBDataBase, projectConstants.co, "CATALOG", "stamp"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let stamps = [];
      let counts = new Map();
      querySnapshot.forEach((document) => {
          // console.log(document);
          let stamp = document.data();
          stamp.id = document.id;
          stamps.push(stamp);
      });
      console.log(counts)

      // Sort the stamps array by the "title" key alphabetically
      stamps.sort((a, b) => {
          if (a.title < b.title) {
              return -1;
          }
          if (a.title > b.title) {
              return 1;
          }
          return 0;
      });

      setProductCatalog(stamps)
  });
}, [FBDataBase,chosenPlanSet]);


///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// FUNCTIONS & METHODS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

////// KEY REFRESHER TO BE ABLE TO REFRESH COMPONENTS //////
//These two functions below are used to re-fresh the SVG & ESVG layers. Reference the freshSVG state.
const refreshSVG = () => {
  let rando = Math.floor(Math.random()*10000) + 1
  setFreshSVG(rando);
}
const refreshESVG = () => {
  let rando = Math.floor(Math.random()*10000) + 1
  setFreshESVG(rando);
}


////// HEADERBAR TOOL CHANGING LOGIC //////
//This simple changes too, or turns the tool to "noTool" if the user de-selects their current tool.
const changeTool = (toolNameString) => {
  if (toolNameString == chosenTool) {setChosenTool("noTool")} else {setChosenTool(toolNameString)}
  console.log(toolNameString)
}

///////////////////////////////
////// PIN FUNCTIONALITY 
///////////////////////////////

////// FIREBASE PIN ADDING //////
const addPinToFirestore = async (pin) => {
  try {
      const docRef = await addDoc(collection(FBDataBase,projectConstants.co, chosenPlanSet, "pins"), pin);
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;  // return the id of the newly created document
  } catch (e) {
      console.error("Error adding document: ", e);
  }
}
////// FIREBASE PIN UPDATING //////
const updatePinInFirestore = async (pinId, pin) => {
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "pins", pinId);
  try {
      await setDoc(docRef, pin, { merge: true });
      console.log("Document updated with ID: ", docRef.id);
  } catch (e) {
      console.error("Error updating document: ", e);
  }
}
////// FIREBASE PIN DELETING //////
const deletePinFromFirestore = async (pinId) => {
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "pins", pinId);
  try {
      await deleteDoc(docRef);
      console.log(`Document with ID: ${pinId} deleted successfully.`);
  } catch (e) {
      console.error(`Error deleting document: ${pinId}. Error: ${e}`);
  }
}


////// INTERNAL PIN ADDING OR EDITING //////
const handlePinSubmit = updatedPin => {
  // If the pin already has an id, it is an existing pin, so update it in Firestore.
  if (updatedPin.id) {
    updatePinInFirestore(updatedPin.id, updatedPin);
  } 
  // If the pin does not have an id, it is a new pin, so add it to Firestore.
  else {
    // Remove the id property from the new pin object
    const {id, ...pinWithoutId} = updatedPin;
    addPinToFirestore(pinWithoutId).then(id => {
      // Save the id for future updates and update the pin in the local state.
      updatedPin.id = id;
      setEditingPinIndex(id);
      // setChosenToolOption("addPin")
    });
  }
};
////// INTERNAL PIN REMOVAL //////
const removePinFunc = (pin,indx) => {
  let pinListCopy = [...pinList]
  pinListCopy.splice(indx,1)
  setPinList(pinListCopy)
  deletePinFromFirestore(pin.id)
}
////// PIN EDIT OR CREATION CANCEL //////
const handlePinCancel = () => {
setEditingPinIndex(null);
};
////// PIN EDIT OR CREATION CANCEL //////
function getPageNumberFromFilename(filename) { //DO I EVEN USE THIS ? TEST DELETION IN TESTING ENVIRONMENT.
  const match = filename.match(/_Page_(\d+)/);
  return match ? parseInt(match[1]) : 0;
}
////// TELEPORT AKA NAVIGATE TO SPECIFIC PIN LOGIC //////
const teleportToPin = (pin) => {
  console.log(pin)
  setPageNum(pin.pageNum)
  pageNumRef.current.value = pin.pageNum

  let zoomScale = 0.4;
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  setChosenToolOption("")
  setPinListOpen(false)
  setTimeout(()=>{
    setFramePos({
      x: screenWidth / 2 - pin.x * zoomScale,
      y: screenHeight / 2 - pin.y * zoomScale,
      scale: zoomScale,
    });
  },100)
}

///////////////////////////////
////// MARK FUNCTIONALITY 
///////////////////////////////
////// INTERNAL ADDITION OF MARK FUNCTION //////
const addMarkFunc = (markup) => {
  addMarkToFirestore(markup).then(id => {
    markup.id = id
  })
  console.log(markup)
  setMarksList([...marksList, markup])
}
////// INTERNAL DELETION OF MARK FUNCTION //////
const removeMarkFunc = (markup,index) => {
  let marksListCopy = [...marksList]
  marksListCopy.splice(index,1)
  setMarksList(marksListCopy)
  deleteMarkFromFirestore(markup.id)
}
////// FIREBASE DELETION OF MARK FUNCTION //////
const deleteMarkFromFirestore = async(id) => {
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "marks", id);
  try {
      await deleteDoc(docRef);
      console.log(`Document with ID: ${id} deleted successfully.`);
  } catch (e) {
      console.error(`Error deleting document: ${id}. Error: ${e}`);
  }
}
////// FIREBASE ADDITION OF MARK FUNCTION //////
const addMarkToFirestore = async(data) => {
  try {
    const docRef = await addDoc(collection(FBDataBase,projectConstants.co, chosenPlanSet, "marks"), data);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;  // return the id of the newly created document
} catch (e) {
    console.error("Error adding document: ", e);
}
}

///////////////////////////////
////// MEASURES FUNCTIONALITY 
///////////////////////////////
////// ADD MEASUREMENT //////
const addMeasureFunc = (measure) =>{
  console.log(measure)
  
  addMeasureToFirestore(measure).then(id=>{
    measure.id = id
  })
  setMeasureList((prev)=>([...prev,measure]))
}
const addMeasureToFirestore = async(data) => {
  try {
    const docRef = await addDoc(collection(FBDataBase,projectConstants.co, chosenPlanSet, "measures"), data);
    console.log("Document written with ID: ", docRef.id);
    setForceMeasureUpdate(prev => !prev)
    return docRef.id;  // return the id of the newly created document
} catch (e) {
    console.error("Error adding document: ", e);
}
}
const removeMeasureFunc = (measure,index) => {
  console.log(measure)
  //RUN FIREBASE DELETE HERE
  deleteMesureFromFirestore(measure.id)
}
const deleteMesureFromFirestore = async(id) => {
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "measures", id);
  try {
      await deleteDoc(docRef);
      console.log(`Document with ID: ${id} deleted successfully.`);
      setForceMeasureUpdate(prev => !prev)
  } catch (e) {
      console.error(`Error deleting document: ${id}. Error: ${e}`);
  }
}



///////////////////////////////
////// ESTIMATES FUNCTIONALITY 
///////////////////////////////
////// FIREBASE PRODUCT ADDING //////
const submitNewProduct = (prod) => {
    // const {id, ...prodNoId} = prod;
    
    const isDuplicate = chosenItemsList.some(item =>
      item.type === prod.type &&
      item.title === prod.title &&
      item.cost === prod.cost &&
      item.color === prod.color &&
      item.shape === prod.shape &&
      item.icon === prod.icon
    );
    if (!isDuplicate) {
      addProductCatalogFirestore(prod).then(id => {
        prod.refId = id
        addProductToFirestore(prod).then(id=>{})
        setChosenItemsList((prev)=>([...prev,prod]))
        
      });
    }
    
}
const updateProduct = async (newProductData, id) => {
  // Update the Firestore document
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "products", id);
  await updateDoc(docRef, newProductData);

  // Update the product in local state
  setChosenItemsList(prev => {
    // Find the index of the product to be updated
    const indexToUpdate = prev.findIndex(product => product.id === id);
    
    // Make a new array that has the updated product
    const updatedList = [
      ...prev.slice(0, indexToUpdate),
      {
        ...prev[indexToUpdate],
        ...newProductData
      },
      ...prev.slice(indexToUpdate + 1)
    ];

    // Return the new list
    return updatedList;
  });

  // Close the edit mode
  setProductEditState(false);
}
const addFromCatalog = (prod) => {
  const isDuplicate = chosenItemsList.some(item =>
    item.type === prod.type &&
    item.title === prod.title &&
    item.cost === prod.cost &&
    item.color === prod.color &&
    item.shape === prod.shape &&
    item.icon === prod.icon
  );
  if (!isDuplicate) {
    addProductToFirestore(prod).then(id=>{})
    setChosenItemsList((prev)=>([...prev,prod]))
  }
}
const addProductCatalogFirestore = async (product) => {
  try {
    const docRef = await addDoc(collection(FBDataBase,projectConstants.co, "CATALOG",product.type), product);
    console.log("Document written with ID: ", docRef.id);
    setForceUpdate(prev => !prev)
    return docRef.id;  // return the id of the newly created document
} catch (e) {
    console.error("Error adding document: ", e);
}
}
const addProductToFirestore = async (product) => {
  try {
      const docRef = await addDoc(collection(FBDataBase,projectConstants.co, chosenPlanSet, "products"), product);
      console.log("Document written with ID: ", docRef.id);
      setForceUpdate(prev => !prev)
      return docRef.id;  // return the id of the newly created document
  } catch (e) {
      console.error("Error adding document: ", e);
  }
}

const deleteProductFromList = async(id,i) => {
  console.log(id)
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "products", id);
  try {
      // 1. Delete the product
      await deleteDoc(docRef);
      console.log(`Document with ID: ${id} deleted successfully.`);

      // 2. Query the stamps collection for stamps with a matchingId equal to the product id
      const stampsCollectionRef = collection(FBDataBase, projectConstants.co, chosenPlanSet, "stamps");
      const q = query(stampsCollectionRef, where("matchingId", "==", id));
      const querySnapshot = await getDocs(q);

      // 3. Delete each matching stamp
      querySnapshot.forEach(async (doc) => {
        console.log(`Deleting stamp with ID: ${doc.id}`);
        await deleteDoc(doc.ref);
      });
      setChosenItemsList((prev) => prev.filter(item => item.id !== id))
      setForceUpdate(prev => !prev)
  } catch (e) {
      console.error(`Error deleting document: ${id}. Error: ${e}`);
  }
}

const processEstimateSVG = (svg) => {
  addStamp(svg).then(id=>{
    svg.id = id
    console.log(id)
    setProductStamps((prev)=>([...prev,svg]))
  })
  
}
const addStamp = async(stamp) => {
  try {
    const docRef = await addDoc(collection(FBDataBase,projectConstants.co, chosenPlanSet, "stamps"), stamp);
    console.log("Document written with ID: ", docRef.id);
    return(docRef.id)
    
  } catch (e) {
      console.error("Error adding document: ", e);
  }
  
}
const trashStamp = async(id) => {
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "stamps", id);
  try {
      await deleteDoc(docRef);
      console.log(`Document with ID: ${id} deleted successfully.`);
  } catch (e) {
      console.error(`Error deleting document: ${id}. Error: ${e}`);
  }
}

const updateStampInFirestore = async (stampId, data) => {
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "stamps", stampId);
  try {
      await setDoc(docRef, data, { merge: true });
      console.log("Document updated with ID: ", docRef.id);
  } catch (e) {
      console.error("Error updating document: ", e);
  }
}
const multiplyStampsFunc = () => {
  console.log(multiplyRef.current.value);
  let newMultiplier = parseInt(multiplyRef.current.value);
  console.log('Selected stamp indexes:', selectedStampIndexes);
  selectedStampIndexes.forEach((id) => {
    let prodStamp = productStamps.find(stamp => stamp.id === id);
    if (prodStamp) {
      console.log("match", id);
      console.log(prodStamp.multiplier);
      prodStamp.multiplier = newMultiplier;
      updateStampInFirestore(prodStamp.id, prodStamp);
    }
  });
  console.log(productStamps);
}
const deleteMultipleStampsFunc = () => {
  console.log('test')
  console.log([...selectedStampIndexes])
  selectedStampIndexes.map((id,i)=>{trashStamp(id)})
  setSelectedStampIndexes([])
}













///////////////////////////////
////// BOOKMARK FUNCTIONALITY 
///////////////////////////////
////// ADD BOOKMARK TO LIST FROM FORM //////
const addBookmarkToList = async(page) => {
  setPageAddingState(false)
  addBookMarkToFirebase(page).then(id => {
      // Save the id for future updates and update the pin in the local state.
      page.id = id; //I don't think I need this..
    });
  console.log(favoritePages)
}
////// ADD BOOKMARK TO FIREBASE //////
const addBookMarkToFirebase = async(page) =>{
  try {
    const docRef = await addDoc(collection(FBDataBase, projectConstants.co, chosenPlanSet, "pages"), page);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;  // return the id of the newly created document
  } catch (e) {
      console.error("Error adding document: ", e);
  }
}
////// DELETE BOOKMARK FROM FIREBASE //////
const delBookMarkFromFirebase = async (pageId) => {
  const docRef = doc(FBDataBase,projectConstants.co, chosenPlanSet, "pages", pageId);
  try {
      await deleteDoc(docRef);
      console.log(`Document with ID: ${pageId} deleted successfully.`);
  } catch (e) {
      console.error(`Error deleting document: ${pageId}. Error: ${e}`);
  }
}

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML RETURNS AND FUNCTIONAL COMPONENTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

if (imageSet.length<1){return(<div className='loading-icon'><BsHourglassSplit /></div>)}
return (
  <div style={{
    overflow: "hidden",
    height: "100vh",
  }}>
    <OptionsBar 
      chosenToolOption={chosenToolOption} 
      setChosenToolOption={setChosenToolOption} 
      chosenTool={chosenTool}  
      setPinListOpen={setPinListOpen}
      pinListOpen={pinListOpen}
      setDrawingOptions={setDrawingOptions}
      drawingOptions={drawingOptions}
      setPinVis={setPinVis}
      pinVis={pinVis}
      setMarkVis={setMarkVis}
      markVis={markVis}
      chosenPalette={chosenPalette}
      setChosenPalette={setChosenPalette}
      pageConstants={pageConstants}
      setPageConstants={setPageConstants}
      chosenItemsList={chosenItemsList}
      setChosenItemsList={setChosenItemsList}
      chosenProduct={chosenProduct}
      setChosenProduct={setChosenProduct}
      productAddingState={productAddingState}
      setProductAddingState={setProductAddingState}
      iconRegistry={iconRegistry}
      deleteProductFromList={deleteProductFromList}
      countState={countState}
      setProductVis={setProductVis}
      productVis={productVis}
      productEditState={productEditState}
      setProductEditState={setProductEditState}
      forceUpdate={forceUpdate}
      setMeasureVis={setMeasureVis}
      measureVis={measureVis}
      selectedStampIndexes={selectedStampIndexes}
      multiplyStampsFunc={multiplyStampsFunc}
      multiplyRef={multiplyRef}
    />
    <HeaderBar
      isDemo={isDemo}
      chosenTool={chosenTool}
      pageNumRef={pageNumRef}
      setChosenOptionsTool={setChosenToolOption}
      setChosenTool={changeTool}
      setDarkMode={setDarkMode}
      darkMode={darkMode}
      pageCount={pageCount}
      pageNum={pageNum}
      setPageNum={setPageNum}
      setListPageToggle={setListPageToggle}
      setChosenProduct={setChosenProduct}
    />
    <ZoomButtons 
      currentScaleRef={currentScaleRef}
      framePos={framePos}
      setFramePos={setFramePos}
    />
    <PlanStage
      removePinFunc={removePinFunc}
      pageConstants={pageConstants}
      setPinList={setPinList}
      pinList={pinList}
      chosenToolOption={chosenToolOption} 
      imageSet={imageSet} 
      darkMode={darkMode} 
      pageCount={pageCount} 
      pageNum={pageNum}
      setEditingPinIndex={setEditingPinIndex}
      framePos={framePos}
      setFramePos={setFramePos}
      currentScaleRef={currentScaleRef}
      viewBoxRef={viewBoxRef}
      marksList={marksList}
      setMarksList={setMarksList}
      drawingOptions={drawingOptions}
      addMarkFunc={addMarkFunc}
      removeMarkFunc={removeMarkFunc}
      handlePinSubmit={handlePinSubmit}
      addMeasureFunc={addMeasureFunc}
      measureList={measureList}
      measureVis={measureVis}
      eviewBoxRef={eviewBoxRef}
      chosenItemsList={chosenItemsList}
      setChosenItemsList={setChosenItemsList}
      productStamps={productStamps}
      chosenProduct={chosenProduct}
      addStamp={processEstimateSVG}
      chosenTool={chosenTool}
      productVis={productVis}
      removeMeasureFunc={removeMeasureFunc}
      forceMeasureUpdate={forceMeasureUpdate}
      trashStamp={trashStamp}
      selectedStampIndexes={selectedStampIndexes}
      setSelectedStampIndexes={setSelectedStampIndexes}
      setPageConstants={setPageConstants}
      shiftToggle={shiftToggle}
      iconRegistry={iconRegistry}
      escButtonHit={escButtonHit}
      freshESVG={freshESVG}
      freshSVG={freshSVG}
    />
    {editingPinIndex != null && (
      <PinPopup
        pin={pinList.find(pin=>pin.id===editingPinIndex)}
        setEditingPinIndex={setEditingPinIndex}
        onSubmit={handlePinSubmit}
        onCancel={handlePinCancel}
        chosenToolOption={chosenToolOption}
        setChosenToolOption={setChosenToolOption}
      />
    )}
    {chosenToolOption === "listPin" && (
      <PinListUI
        pinList={pinList}
        teleportToPin={teleportToPin}
      />
    )}
    {chosenTool === "pageTool" && (
      <PageSidebar
      favoritePages={favoritePages}
      setFavoritePages={setFavoritePages}
      setPageNum={setPageNum}
      pageNumRef={pageNumRef}
      setPageAddingState={setPageAddingState}
      pageAddingState={pageAddingState}
      delBookMarkFromFirebase={delBookMarkFromFirebase}
      />
    )}
    {pageAddingState == true && (
      <BookmarkPopup
      pageCount={pageCount}
      pageNum={pageNum}
      addBookmarkToList={addBookmarkToList}
      setPageAddingState={setPageAddingState}
      />
    )}
    {/* {(listPageToggle == true && (
      <PlansListPage setPlanSetList={setPlanSetList} planSets={planSetList} setChosenPlanSet={setChosenPlanSet} setListPageToggle={setListPageToggle} />
    ))} */}
    {(productAddingState == true || productEditState.bool == true )&& (
      <NewProductPopup chosenItemsList={chosenItemsList}
        setChosenItemsList={setChosenItemsList}
        setProductAddingState={setProductAddingState}
        submitNewProduct={submitNewProduct} 
        setIconRegistry={setIconRegistry}
        iconRegistry={iconRegistry}
        productCatalog={productCatalog}
        addFromCatalog={addFromCatalog}
        productEditState={productEditState}
        setProductEditState={setProductEditState}
        deleteProductFromList={deleteProductFromList}
        updateProduct={updateProduct}
      />
    )}
  </div>
);
}
    

export default App;
